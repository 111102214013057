import Form from 'react-bootstrap/Form';
import React from "react";
import {FormGroup} from "react-bootstrap";
interface Props {
    id: string;
    name: string;
    value?: any;
    checked?: boolean;
    disabled?: boolean;
    label?: string;
    defaultValue?: any;
    onChange?(change: any): void;
}
export default function FormSwitch(props: Props ) {


    return (
        <>
            <FormGroup className="switch">
                <Form.Check
                    type="switch"
                    id={props.id}
                    name={props.name}
                    label={props.label}
                    disabled={props.disabled}
                    value={props.value}
                    checked={props.checked}
                    defaultChecked={props.value}
                    onChange={props.onChange}
                />
            </FormGroup>
        </>

    );
}
