import Form from 'react-bootstrap/Form';
import {FormGroup} from "react-bootstrap";
import React from "react";
interface Props {
    formik: any;
    name: string;
    type: string;
    label: string;
    value: string;
    disabled?: boolean;
    error?: string;
}

export default function FormUpload(props: Props ) {

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (file) {

            const base64String = await fileToBase64(file);

            const newObj = {
                name: file.name,
                type: file.type,
                base64String: base64String,
                size: file.size
            };
            props.formik.setFieldValue(props.name, newObj);
        }

    };

    function fileToBase64(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    // The 'result' property contains the data as a Base64 encoded string
                    resolve(reader.result);
                } else {
                    reject(new Error('Failed to read the file'));
                }
            };

            reader.onerror = () => {
                reject(new Error('Error occurred while reading the file'));
            };

            // Read the file as a Data URL, which includes Base64 encoding
            reader.readAsDataURL(file);
        });
    }

    return (
        <FormGroup>
            <Form.Label>{ props.label }</Form.Label>
            <Form.Control name={props.name} type={props.type} disabled={props.disabled} onChange={handleFileChange}/>
            { props.value !== "" && props.error && <Form.Text className="mt-2" style={{color: "#FF0000"}}>
                {props.error}
            </Form.Text>}
        </FormGroup>
    );
}


