import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {ToastProvider} from "./services/ToastService";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
      <ToastProvider>
          <div className="d-flex flex-column justify-content-between" style={{minHeight: '100vh'}}>
              <div>
                  <Header></Header>
              </div>
              <div className="flex-fill">
                  <App/>
              </div>
              <Footer></Footer>
          </div>

      </ToastProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
