import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import {Button} from "react-bootstrap";
import FormSelect from "./FormSelect";
import 'leaflet/dist/leaflet.css';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, {LatLngExpression} from 'leaflet';
import { Map as LeafletMap } from 'leaflet';
import {Prestazione, Struttura} from "../assets/interface/interface";
import {convertStringToNumberArray} from "../assets/utilities/utilities";


interface Props {
    formik: any;
    prestazioni: Prestazione[];
    strutture: Struttura[];
}

const BookingType: React.FC<Props> = (props: Props) => {
    const mapRef = useRef<LeafletMap | null>(null);

    const initialPosition: any = [45.840748, 8.912932]; // Inserisci le coordinate iniziali qui
    const initialZoom = 9; // Imposta lo zoom iniziale qui

    const [struttura, setStruttura] = useState<Struttura>();

    // Definisci l'icona personalizzata
    const customIcon = new L.Icon({
        iconUrl: "/images/maps-and-flags.png", // Percorso dell'immagine personalizzata
        iconSize: [32, 32], // Dimensioni dell'icona [larghezza, altezza]
        iconAnchor: [16, 32], // Punto in cui l'icona deve essere posizionata rispetto alla sua posizione
        popupAnchor: [0, -32], // Punto in cui deve essere posizionato il popup rispetto alla sua posizione
    });

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    // Esempio di aggiornamento della posizione con transizione fluida
    const updateMapPositionWithTransition = (position: LatLngExpression) => {
        if (mapRef.current) {
            mapRef.current.flyTo(position, 15, {
                duration: 1.5 // Durata della transizione in secondi
            });
        }
    };

    const handleMarkerClick = (pin: Struttura) => {
        updateMapPositionWithTransition(convertStringToNumberArray(pin.coordinate));
        props.formik.setFieldValue("sportello", {value: pin.id.toString(), label: props.strutture.filter(str => str.id === pin.id)[0].descrizione});
    };

    const onChangeSportello = (change: any) => {
        const strutturaSelected = props.strutture.filter(str => str.id === change.value)[0];
        setStruttura(strutturaSelected);
        updateMapPositionWithTransition(convertStringToNumberArray(strutturaSelected.coordinate));
        props.formik.setFieldValue("sportello", change);
    }

    // Aggiorna la posizione della mappa
    const updateMapPosition = (position: L.LatLngExpression) => {
        const map = mapRef.current;
        if (map) {
            map.setView(position, map.getZoom());
        }
    };

    // Aggiorna lo zoom della mappa
    const updateMapZoom = (zoom: number) => {
        const map = mapRef.current;
        if (map) {
            map.setView(map.getCenter(), zoom);
        }
    };

    useEffect(() => {
        updateMapPosition(initialPosition);
        updateMapZoom(initialZoom);
    }, []);

    return (
        <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>
            <Form onSubmit={props.formik.handleSubmit}>
                <div className="p-4 rounded-lg mb-100">
                    <h2 className="my-3">
                        Tipologia
                    </h2>
                    <div className="row pb-4">
                        <div className="col-md-12 my-3">
                            <FormSelect name="tipo_prenotazione" formik={props.formik} label="Tipologia Prestazione" value={props.formik.values.tipologia_prestazione}
                                        options={props.prestazioni}></FormSelect>
                        </div>

                        { props.formik.values?.tipo_prenotazione?.value && <div className="mt-1">
                            Prima di procedere ricordati di avere questi documenti:
                            <br/>
                            <br/>
                            <ul>
                                {props.prestazioni.filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)[0]?.documenti.map((doc, index) => (
                                    <li key={index}>{doc}</li>))}
                            </ul>

                            Il costo unitario della prestazione è di:

                            {
                                props.prestazioni
                                    .filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)
                                    .map(prestazione => (
                                        prestazione.tariffe?.length === 1 && (
                                            <React.Fragment key={prestazione.id}>
                                                <span> € {prestazione.tariffe[0].prezzo}. </span>
                                            </React.Fragment>
                                        )
                                    ))
                            }


                            {
                                props.prestazioni
                                    .filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)
                                    .map(prestazione => (
                                        prestazione.tariffe?.length > 1 && (
                                            <React.Fragment key={prestazione.id}>
                                                <ul className="mb-0">
                                                    {prestazione.tariffe.map((tariffa, index) => (
                                                        <li key={"tariffa" + index}>€ {tariffa.prezzo}: {tariffa.descrizione_tariffa}</li>
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                        )
                                    ))
                            }

                            In caso di prenotazione per più animali il costo della prestazione va moltiplicato per il numero di animali. Se il totale è uguale o superiore a 77,47€ vanno aggiunti 2 € del bollo.

                            <br/>
                            <br/>

                            * Per effettuare il pagamento accedi al sito web di <a href="https://pagamentinlombardia.servizirl.it/pa/home.html" target="_blank"> Regione Lombardia</a> nella sezione “<b>Cerca Ente</b>” seleziona “<a>ATS dell'INSUBRIA</a>”, scegli tra le tipologie di servizio la voce “<b>Servizio Veterinario</b>” e procedi con il pagamento della specifica tariffa.

                            {(props.formik.values?.tipo_prenotazione?.value === "1" || props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                ** Informarsi presso Ambasciata/Consolato/sito ufficiale.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "1") && <>
                                <br/>
                                <br/>
                                *** Scarica qui il documento per la <a target="_blank" href="/allegati/documenti_a60/Rich_Passaporto_2022.pdf">Richiesta di Rilascio Passaporto</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "2") && <>
                                <br/>
                                <br/>

                                ** Scarica qui il documento per la <a target="_blank" href="/allegati/documenti_a60/Modulo_iscrizione_anagrafe_canina_22.pdf">Dichiarazione cessione animale</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                *** Scarica qui il documento per il <a target="_blank" href="/allegati/documenti_a13_05/Rich_CertificatoEspatrio_2022.pdf">Certificato di Espatrio</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                **** Da presentare al momento del ritiro del certificato, rispettando il termine delle 48 ore.
                            </>}

                            <br/>
                            <br/>

                            <u><i>Presentarsi per il ritiro di persona senza animale o con delega del proprietario allegando fotocopia di un documento d’Identità valido.</i></u>
                        </div>}

                    </div>

                    <hr />

                    <h2 className="my-3 pt-3">
                        Sportello
                    </h2>
                    <div className="row">
                        <div className="col-md-6 my-3">
                            <div style={{ width: '100%', height: '400px' }}>
                                <MapContainer ref={mapRef} center={initialPosition} zoom={initialZoom} style={{ width: "100%", height: '100%', zIndex: 0 }}>
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                                    {props.strutture.map((pin) => (
                                        <Marker
                                            key={pin.id}
                                            position={convertStringToNumberArray(pin.coordinate)}
                                            icon={customIcon}
                                            eventHandlers={{
                                                click: () => handleMarkerClick(pin)
                                            }}
                                        >
                                        </Marker>
                                    ))}
                                </MapContainer>
                            </div>
                        </div>
                        <div className="col-md-6 my-3">
                            {props.strutture && <FormSelect name="sportello" formik={props.formik} label="Sportello"
                                                             value={props.formik.values.sportello}
                                                             options={props.strutture} onChange={onChangeSportello}></FormSelect>}

                            {struttura && <div>
                                <br/><br/>
                                <a href="mailto:">{ struttura.email}</a>
                                <p>
                                    { struttura.indirizzo}<br/>
                                    Tel. <a href={ "tel:+39" + struttura.telefono_1}>{ struttura.telefono_1}</a> {struttura.telefono_2 && <span>- <a href={ "tel:+39" + struttura.telefono_2}>{ struttura.telefono_2}</a></span>}
                                </p>
                            </div>}

                        </div>

                    </div>

                    <div style={containerActionsStyles}>
                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button variant="primary" type="submit" disabled={!props.formik.isValid || !props.formik.dirty}>Prossimo</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default BookingType;
