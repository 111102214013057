import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import BookingType from "../components/BookingType";
import OwnershipData from "../components/OwnershipData";
import Documents from "../components/Documents";
import Calendar from "../components/Calendar";
import Summary from "../components/Summary";
import {useFormik} from "formik";
import * as Yup from 'yup';
import ModalConfirm from "../components/ModalConfirm";
import {Book, Prestazione, Struttura} from "../assets/interface/interface";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {cleanJSON, isValidCodiceFiscale} from "../assets/utilities/utilities";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import Loading from "../components/Loading";
import {config} from "../config";
import {ToastContext} from "../services/ToastService";

const CreateBooking = () => {
    const [key, setKey] = useState<string>('prenotazione');
    const tabs = ['prenotazione', 'informazioni-personali', 'documenti', 'calendario', 'riepilogo'];

    const [prestazioni, setPrestazioni] = useState<Prestazione[]>([]);
    const [strutture, setStrutture] = useState<Struttura[]>([]);

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [book, setBook] = useState<Book | any>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const fileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/x-zip-compressed'];

    // Definisci il limite di dimensione del file (10 MB in byte)
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 M
    const {showToast} = useContext(ToastContext)!;

    const goToNextTab = () => {
        const currentIndex = tabs.indexOf(key);
        const nextIndex = currentIndex + 1;
        if (nextIndex < tabs.length) {
            setKey(tabs[nextIndex]);
        }
    };

    const goToBackTab = () => {
        const currentIndex = tabs.indexOf(key);
        const nextIndex = currentIndex - 1;
        if (nextIndex < tabs.length) {
            setKey(tabs[nextIndex]);
        }
    };

    const formikBookingType = useFormik({
        initialValues: {
            tipo_prenotazione: null,
            sportello: null
        },
        validationSchema: Yup.object({
            tipo_prenotazione: Yup.mixed().required(""),
            sportello: Yup.mixed().required(""),
        }),
        onSubmit: (values) => {
            setBook({...book, ...values});
            goToNextTab();
            scrollToTop();
        }
    });

    const formikOwnershipData = useFormik({
        initialValues: {
            nome: "",
            cognome: "",
            codicefiscale: "",
            indirizzo: "",
            citta: "",
            cap: null,
            provincia: "",
            telefono: "",
            email: "",
            documento_carta_identita: "",
            documento_codicefiscale: "",
            animali_per_prenotazione: 1,
            dati_cedente: false,
            dati_cedente_nome: "",
            dati_cedente_cognome: "",
            dati_cedente_luogo_di_nascita: "",
            dati_cedente_data_di_nascita: "",
            dati_cedente_sesso: "",
            dati_cedente_codicefiscale: "",
            dati_cedente_citta: "",
            dati_cedente_indirizzo: "",
            dati_cedente_telefono: null,
            dati_cedente_email: "",
            data_di_acquisto: "",
            dati_cedente_carta_identita: "",
            dati_cedente_documento_codicefiscale: "",
        },
        validationSchema: Yup.object({
            nome: Yup.string().required(""),
            cognome: Yup.string().required(""),
            codicefiscale: Yup.string().required("").test(
                'isValidCodiceFiscale',
                'Il codice fiscale non è valido',
                value => isValidCodiceFiscale(value || ''),
            ),
            indirizzo: Yup.string().required(""),
            citta: Yup.string().required(""),
            cap: Yup.number().required(""),
            provincia: Yup.string().required(""),
            telefono: Yup.number().required(""),
            email: Yup.string().email("Il campo deve essere composto da una mail").required(""),


            documento_carta_identita: Yup.mixed().nullable()
                .test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                    if (!value) return false;
                    return fileTypes.includes(value.type);
                })
                .test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                    if (!value) return false;
                    return value.size <= MAX_FILE_SIZE;
                }),

            documento_codicefiscale: Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }),
            animali_per_prenotazione: Yup.number().required("").positive("Il numero di animali deve essere maggiore di zero"),
            dati_cedente: Yup.boolean(),
            dati_cedente_nome: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_cognome: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_luogo_di_nascita: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_data_di_nascita: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_sesso: Yup.mixed().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.mixed().required("");
                } else {
                    return Yup.mixed().nullable();
                }
            }),
            dati_cedente_codicefiscale: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("").test(
                        'isValidCodiceFiscale',
                        'Il codice fiscale non è valido',
                        value => isValidCodiceFiscale(value || ''),
                    )
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_citta: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_indirizzo: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_telefono: Yup.number().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.number().required("");
                } else {
                    return Yup.number().nullable();
                }
            }),
            dati_cedente_email: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().email().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            data_di_acquisto: Yup.string().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.string().required("");
                } else {
                    return Yup.string().nullable();
                }
            }),
            dati_cedente_carta_identita: Yup.mixed().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                        if (!value) {
                            return false;
                        }
                        return fileTypes.includes(value.type);
                    }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                        if (!value) return false;
                        return value.size <= MAX_FILE_SIZE;
                    })
                } else {
                    return Yup.mixed().nullable();
                }
            }),
            dati_cedente_documento_codicefiscale: Yup.mixed().when('dati_cedente', ([field]) => {
                if (field) {
                    return Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                        if (!value) {
                            return false;
                        }
                        return fileTypes.includes(value.type);
                    }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                        if (!value) return false;
                        return value.size <= MAX_FILE_SIZE;
                    })
                } else {
                    return Yup.mixed().nullable();
                }
            }),
        }),
        onSubmit: (values) => {
            setBook({...book, ...values});
            goToNextTab();
            scrollToTop();
        }
    });

    const formikDocuments = useFormik({
        initialValues: {
            certificato_vaccinazione_antirabbica: "",
            certificato_iscrizione_anagrafe: "",
            certificazione_microchip: "",
            certificato_espatrio: "",
            documento_richiesta_passaporto: "",
            eventuali_documenti: "",
            delega: false,
            documento_delega: "",
            documento_di_pagamento: "",
            documento_cedente_acquirente: "",
            privacy: false
        },
        validationSchema: Yup.object({
            certificato_vaccinazione_antirabbica: (book.tipo_prenotazione?.value == "3" || book.tipo_prenotazione?.value == "1")  ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (book.tipo_prenotazione?.value == "1" && !value) {
                    return true
                } else if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (book.tipo_prenotazione?.value == "1" && !value) {
                    return true
                } else if (!value) {
                    return false;
                }
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),


            certificato_iscrizione_anagrafe: (book?.tipo_prenotazione?.value == "1" || book?.tipo_prenotazione?.value == "3")  ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            certificazione_microchip:( book?.tipo_prenotazione?.value === "3" || book?.tipo_prenotazione?.value === "1") ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            certificato_espatrio: book?.tipo_prenotazione?.value === "3" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            documento_richiesta_passaporto: book?.tipo_prenotazione?.value === "1" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            documento_cessione_cane: book?.tipo_prenotazione?.value === "2" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            documento_cedente_acquirente: book?.tipo_prenotazione?.value === "2" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            eventuali_documenti: (book?.tipo_prenotazione?.value == "1" || book?.tipo_prenotazione?.value == "3") ? Yup.mixed()
                .test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP ciao', (value: any) => {
                    if (!value) {
                        return true;
                    }
                    return fileTypes.includes(value.type);
                })
                .test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                    if (!value) return true;
                    return value.size <= MAX_FILE_SIZE;
                })
                    : Yup.mixed().nullable(),
            delega: Yup.boolean(),
            documento_delega: Yup.mixed().when('delega', ([field]) => {
                if (field) {
                    return Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                        if (!value) {
                            return false;
                        }
                        return fileTypes.includes(value.type);
                    }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                        if (!value) return false;
                        return value.size <= MAX_FILE_SIZE;
                    })
                } else {
                    return Yup.mixed().nullable();
                }
            }),
            documento_di_pagamento: Yup.mixed().required("").test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }),
            privacy: Yup.boolean().required("").oneOf([true])
        }),
        onSubmit: (values) => {
            setBook({...book, ...values});
            goToNextTab();
            scrollToTop();
        }
    });

    const setDateAndTime = (dateAndTime: Date | null) => {
        setBook({...book, ...{date_prenotazione: dateAndTime}});
        goToNextTab();
        scrollToTop();
    }

    const handleCreateBook = () => {
        setModalShow(false);
        createBook();
    }

    const createBook = () => {
        setIsLoading(true);

        fetch(`${config.apiUrl}/front/insert_prenotazione2.php`, {
            method: 'POST',
            body: JSON.stringify(cleanJSON({...book})),
        })
            .then(response => response.json())
            .then(data => {
                // Esegui azioni dopo l'invio della post (se necessario)
                const id = data.order;
                navigate('booking-request/' + id);
            })
            .catch(error => {
                setIsLoading(false);
                showToast("danger", "Errore", "Errore durante la creazione della prenotazione");
                console.error('Errore durante l\'invio della post:', error);
            });
    };


    const getStrutture = async () => {
        try {
            const response = await axios.get<Struttura[]>(
                `${config.apiUrl}/front/get_struttura.php`
            );
            setStrutture(response.data);
        } catch (error) {
            // Gestisci eventuali errori qui
            showToast("danger", "Errore", "Errore durante la richiesta delle strutture");
            console.error('Errore durante la richiesta API:', error);
        }
    };

    const getPrestazioni = async () => {
        try {
            const response = await axios.get<Prestazione[]>(
                `${config.apiUrl}/front/get_prestazioni.php`
            );
            setPrestazioni(response.data);
        } catch (error) {
            // Gestisci eventuali errori qui
            showToast("danger", "Errore", "Errore durante la richiesta delle prestazioni");
            console.error('Errore durante la richiesta API:', error);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        getPrestazioni();
        getStrutture();
    }, []);

    if (prestazioni && strutture) {
        return (
            <div className="container">
                <h1>Richiesta di prenotazione prestazione</h1>
                <p>Scegli la tua prestazione e compila tutti i campi per la creazione della prenotazione del tuo appuntamento. Se hai bisogno di assistenza, contatta lo sportello selezionato. Forniamo un servizio clienti dedicato per rendere il processo di prenotazione semplice ed efficiente.</p>

                <div className="tab-container">
                    <h2 className="fw-bold fs-4 mb-4">{ book && book?.tipo_prenotazione?.label }</h2>

                    <Tab.Container defaultActiveKey="prenotazione" activeKey={key}>
                        <Row>
                            <Col sm={9} className="mb-5">
                                <Tab.Content className="tab-container-detail">
                                    <Tab.Pane eventKey="prenotazione">
                                        <BookingType formik={formikBookingType} prestazioni={prestazioni}
                                                                                strutture={strutture}></BookingType>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="informazioni-personali">
                                        <OwnershipData formik={formikOwnershipData} goToBackTab={goToBackTab}
                                                       tipologia={book?.tipo_prenotazione?.value}></OwnershipData>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="documenti">
                                        {book && book.tipo_prenotazione && <Documents typeBooking={book.tipo_prenotazione} formik={formikDocuments}
                                                                                      animali_per_prenotazione={book.animali_per_prenotazione}
                                                                                      prestazioni={prestazioni} goToBackTab={goToBackTab}></Documents>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="calendario">
                                        {(key === "calendario" || key === "riepilogo") && book && book.sportello && <Calendar book={book} goToBackTab={goToBackTab}
                                                   setDateAndTime={setDateAndTime}></Calendar>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="riepilogo">
                                        {book && book.tipo_prenotazione && <Summary isSummary={key === "riepilogo"} book={book} strutture={strutture} goToBackTab={goToBackTab} modalShow={() => setModalShow(true)}></Summary>}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="prenotazione" className={(formikBookingType.isValid && formikBookingType.dirty) ? "isValid" : ""}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>Prenotazione</div>
                                                <div className="text-primary icon-check">
                                                    <FontAwesomeIcon icon={faCircleCheck}/>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="informazioni-personali" className={(formikOwnershipData.isValid && formikOwnershipData.dirty) ? "isValid" : ""}
                                                  disabled={!formikBookingType.isValid || !formikBookingType.dirty}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>Informazioni Personali</div>
                                                <div className="text-primary icon-check">
                                                    <FontAwesomeIcon icon={faCircleCheck}/>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="documenti" className={(formikDocuments.isValid && formikDocuments.dirty) ? "isValid" : ""}
                                                  disabled={!formikOwnershipData.isValid || !formikOwnershipData.dirty}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>Documenti</div>
                                                <div className="text-primary icon-check">
                                                    <FontAwesomeIcon icon={faCircleCheck}/>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="calendario" className={(book?.date_prenotazione) ? "isValid" : ""}
                                                  disabled={!formikDocuments.isValid || !formikDocuments.dirty}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>Calendario</div>
                                                <div className="text-primary icon-check">
                                                    <FontAwesomeIcon icon={faCircleCheck}/>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="riepilogo"
                                                  disabled={!book.date_prenotazione}>Riepilogo</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                { (key === "informazioni-personali" || key === "documenti") &&
                                    <div className="mt-4">
                                        <div className="bg-secondary p-4">
                                            <div>
                                                <div className="d-flex flex-row gap-3 align-items-center">
                                                    <div className="fs-3 text-primary">
                                                        <FontAwesomeIcon icon={faTriangleExclamation}/>
                                                    </div>
                                                    <div className="fw-bold text-uppercase text-primary">
                                                        ATTENZIONE
                                                    </div>
                                                </div>

                                                <ol className="mt-3">
                                                    <li>Per il caricamento dei file è necessario utilizzare un file di tipo PDF, PNG, JPEG o una Cartella ZIP.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </Col>
                        </Row>
                    </Tab.Container>
                </div>

                {book && <ModalConfirm book={book} modalShow={modalShow} setModalShow={setModalShow} createBook={handleCreateBook}></ModalConfirm>}

                { isLoading && <Loading />}
            </div>
        );
    } else {
        return <Loading />
    }
};

export default CreateBooking;
