import React from "react";
import {Button} from "react-bootstrap";
import {Book, Struttura} from "../assets/interface/interface";
import InfoSportello from "./InfoSportello";
import MapSportello from "./MapSportello";

interface Props {
    goToBackTab(): void;
    modalShow(): void;
    book: Book;
    strutture: Struttura[];
    isSummary: boolean;
}
const Summary = (props: Props) => {

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    function setShowModal() {
        props.modalShow()
    }

    return (
        <div className="p-4 rounded-lg mb-100">

            <h2 className="my-3">
                Riepilogo
            </h2>

            <div className="wrapper gap-4 pt-2">
                <div>
                    {props.book?.sportello && props.isSummary && <MapSportello struttura={props.strutture.filter(str => str.id === props.book?.sportello?.value)[0]}></MapSportello>}
                </div>
                <div className="bg-secondary p-4 border-1 border-primary" style={{borderStyle: 'solid'}}>
                    <InfoSportello book={props.book}></InfoSportello>
                </div>
            </div>

            <div style={containerActionsStyles}>
                <hr/>
                <div style={actionsStyles}>
                    <div>
                        <Button className="bg-white" variant="outline-primary" onClick={props.goToBackTab}>Indietro</Button>
                    </div>
                    <div>
                        <Button variant="primary" onClick={setShowModal}>Prossimo</Button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Summary;
