export function convertStringToNumberArray(input: string): any {
    if (input) {
        const cleanInput = input.replace(/\s/g, '');
        const stringArray = cleanInput.split(',');
        const numberArray = stringArray.map((numStr) => Number(numStr));
        return numberArray.filter((num) => !isNaN(num));
    }
}

export function formatMinutesToTime(totalMinutes: number){
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = 0; // Assume 00 seconds
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

interface JsonObject {
    [key: string]: any;
}

export function cleanJSON(jsonData: JsonObject | JsonObject[]): any {
    if (Array.isArray(jsonData)) {
        return jsonData.map((item) => cleanJSON(item));
    } else if (typeof jsonData === 'object' && jsonData !== null) {
        if ('label' in jsonData && 'value' in jsonData && Object.keys(jsonData).length === 2) {
            return jsonData['value'];
        } else {
            for (const key in jsonData) {
                jsonData[key] = cleanJSON(jsonData[key]);

                if (jsonData[key] instanceof Date) {
                    jsonData[key] =  jsonData[key].toISOString();
                }
            }
            return jsonData;
        }
    } else {
        return jsonData;
    }
}

function isBreakDays(date: Date, breakDay: number[]): boolean {
    const day = date.getDay();
    return breakDay.includes(day);
}

function addBusinessDays(date: Date, numDays: number, breakDay: number[]): Date {
    let count = 0;
    while (count < numDays) {
        date.setDate(date.getDate() + 1);
        if (!isBreakDays(date, breakDay)) {
            count++;
        }
    }
    return date;
}

export function getFollowingDays(breakDay: number[], followingWorkingDays: number): Date {
    const today = new Date();
    return addBusinessDays(today, followingWorkingDays, breakDay);
}

export function isValidCodiceFiscale(codiceFiscale: string): boolean {
    if (codiceFiscale.length !== 16) {
        return false;
    } else {
        codiceFiscale = codiceFiscale.toUpperCase();

        const monthLetter = /^[ABCDEHLMPRST]+$/;

        const cognome = codiceFiscale.substring(0, 3);
        const nome = codiceFiscale.substring(3, 6);
        const annoNascita = codiceFiscale.substring(6, 8);
        const meseNascita = codiceFiscale.charAt(8);
        const giornoNascita = codiceFiscale.substring(9, 11);
        const codiceComune = codiceFiscale.substring(11, 15);

        return !!(cognome.match(/^[A-Z]{3}$/) &&
            nome.match(/^[A-Z]{3}$/) &&
            annoNascita.match(/^\d{2}$/) &&
            meseNascita.match(monthLetter) &&
            giornoNascita.match(/^\d{2}$/) &&
            codiceComune.match(/^[0-9A-Z]{4}$/)
        );
    }

}

export function isDifferentFromInitial(field: string, fieldInitial: string) {
    return field !== fieldInitial;
}


