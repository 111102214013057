import React, {useEffect, useState} from "react";
import version from "../../package.json";

const Footer = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleWindowResize = () => {
            const windowWidth = window.innerWidth;
            setIsMobile(windowWidth < 768);
        };

        // Aggiungi l'ascoltatore per l'evento "resize" sulla finestra
        window.addEventListener('resize', handleWindowResize);

        // Esegui il controllo iniziale al momento del montaggio del componente
        handleWindowResize();

        // Pulisci l'ascoltatore quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <footer>

            <div className="bg-info text-light mt-5 py-5 ps-3 pe-3">
                <div className={isMobile ?  "container d-flex flex-column gap-5" : "container d-flex flex-row align-items-center gap-5 justify-content-between"}>
                    <div>
                        <a href="https://www.ats-insubria.it/" style={{textDecoration: "none"}}>
                            <h2 className="text-white fw-bold fs-5">ATS Insubria</h2>
                        </a>
                        <div className="text-white text-primary">CUP per prenotazione prestazioni</div>
                    </div>

                    <div className="d-flex flex-row gap-3">
                        <a href="https://www.ats-insubria.it/note-legali" target="_blank" className="text-white text-regular">Note legali</a>
                        <a href="https://www.ats-insubria.it/privacy" target="_blank" className="text-white text-regular">Privacy</a>
                        <a href="https://www.ats-insubria.it/cookie-policy" target="_blank" className="text-white text-regular">Cookie Policy</a>
                    </div>
                </div>
            </div>


            <div className="text-center p-2" style={{backgroundColor: "#001F3D", color: "#FFF", fontSize: "14px"}}>
                v. {version.version}
            </div>

        </footer>
    );
};

export default Footer;
