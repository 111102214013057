import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {Button} from "react-bootstrap";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {format, addMinutes} from 'date-fns';
import axios from "axios";
import {Book} from "../assets/interface/interface";
import {
    formatMinutesToTime,
    getFollowingDays,
} from "../assets/utilities/utilities";
import {config} from "../config";
import {ToastContext} from "../services/ToastService";

interface Props {
    book: Book;
    goToBackTab(): void;
    setDateAndTime(dateAndTime: Date | null): void;
}
const Calendar = (props: Props) => {

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [businessHours, setBusinessHours] = useState<any[]>();

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [events, setEvents] = useState<any[]>([]);
    const [laboratory, setLaboratory] = useState<any>();
    const [slot, setSlot] = useState<number>();

    const [smallestStartTime, setSmallestStartTime] = useState<string>();
    const [largestEndTime, setLargestEndTime] = useState<string>();

    const [startDate, setStartDate] = useState<any>(getFollowingDays([0, 6], 7));

    const validRange = {start: startDate};

    const allDay = [0, 1, 2, 3, 4, 5, 6,];

    const {showToast} = useContext(ToastContext)!;

    const handleDateSelect = (arg: any) => {
        setSelectedDate(arg.start);
    };

    const getUpcomingEvents = async () => {
        try {
            const response = await axios.get<any[]>(
                `${config.apiUrl}/front/get_upcoming_events.php`, {
                    params: {
                        id: props.book.sportello.value
                    }
                });

            const newEvents = response.data.map((evt) => ({
                start: evt.booking_date_time,
                end: evt.booking_date_time_end,
                display: 'background',
            }));

            setEvents(newEvents)
        } catch (error) {
            // Gestisci eventuali errori qui
            showToast("danger", "Errore", "Errore durante la richiesta degli eventi.")
            console.error('Errore durante la richiesta API:', error);
        }
    };

    async function getLaboratorio() {
        try {
            const response = await axios.get(`${config.apiUrl}/front/get_laboratorio.php`, {
                params: {
                    id: props.book.sportello.value
                }
            });

            setLaboratory(response.data);
            setSlot(parseInt(response.data[0].slot));

            const laboratoryHours = response.data.map((lab: { weekday_id: any; day_start_time: any; day_end_time: any; }) => ({
                daysOfWeek: [ lab.weekday_id ],
                startTime: lab.day_start_time,
                endTime: lab.day_end_time,
            }));

            setBusinessHours(laboratoryHours);

            setSmallestStartTime(response.data[0].day_start_time);
            setLargestEndTime(response.data[0].day_end_time);

            let smallestStartTimeLet = response.data[0].day_start_time;
            let largestEndTimeLet = response.data[0].day_end_time;

            for (const dayObject of response.data) {

                if (dayObject.day_start_time === "00:00:00" && dayObject.day_end_time === "00:00:00") {
                    continue;
                }

                if (dayObject.day_start_time < smallestStartTimeLet) {
                    setSmallestStartTime(dayObject.day_start_time);
                    smallestStartTimeLet = dayObject.day_start_time;
                }
                if (dayObject.day_end_time > largestEndTimeLet) {
                    setLargestEndTime(dayObject.day_end_time);
                    largestEndTimeLet = dayObject.day_end_time;
                }
            }


            const workDays = response.data.map((item: any) => parseInt(item.weekday_id));
            const breakDays = allDay.filter(item => workDays.indexOf(item) === -1);
            setStartDate(getFollowingDays(breakDays, 7));

        } catch (error) {
            showToast("danger", "Errore", "Errore durante la richiesta del laboratorio.")
            console.error('Errore durante la richiesta API:', error);
        }
    }

    const cancel = () => {
        props.setDateAndTime(null);
        props.goToBackTab();
    };

    useEffect(() => {
        getLaboratorio();
        getUpcomingEvents();
    }, [])

    useEffect(() => {
        const handleWindowResize = () => {
            const windowWidth = window.innerWidth;
            setIsMobile(windowWidth < 768);
        };

        // Aggiungi l'ascoltatore per l'evento "resize" sulla finestra
        window.addEventListener('resize', handleWindowResize);

        // Esegui il controllo iniziale al momento del montaggio del componente
        handleWindowResize();

        // Pulisci l'ascoltatore quando il componente viene smontato
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className="p-4 rounded-lg mb-100">
            <h2 className="my-3">
                Calendario
            </h2>
            <p>
                Seleziona una data e un orario che preferisci per il tuo appuntamento. Ricorda che le celle grigio chiaro nel calendario indicano gli slot disponibili.
            </p>

            { laboratory && <div className="mt-5">
                <FullCalendar
                    plugins={[timeGridPlugin, interactionPlugin]}
                    initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
                    slotLabelInterval="01:00"
                    slotMinTime={smallestStartTime ? smallestStartTime : "07:00:00"}
                    slotMaxTime={largestEndTime ? largestEndTime : "20:00:00"}
                    selectable={true}
                    slotDuration={slot ? formatMinutesToTime(slot) : "01:00:00"}
                    select={handleDateSelect}
                    expandRows={true}
                    initialDate={selectedDate}
                    validRange={validRange}
                    weekends={true}
                    locale="it"
                    handleWindowResize={true}
                    height="600px"
                    allDaySlot={false}
                    events={events}
                    slotEventOverlap={false}
                    eventOverlap={false}
                    businessHours={businessHours}
                    headerToolbar={{
                        left: 'title',
                        right: 'prev,next',
                    }}
                    selectOverlap={false}
                    selectConstraint="businessHours"
                    selectLongPressDelay={0}
                    longPressDelay={0}
                />

            </div>}

            {selectedDate && <>
                <h2 className="my-3 pt-4">
                    Prenotazione Selezionata:
                </h2>

                <div className="d-flex flex-row gap-3">
                    <div className="bg-secondary p-4 border-1 border-primary flex-fill" style={{borderStyle: 'solid'}}>
                        <div className="d-flex flex-row gap-3 align-items-center">
                            <div className="fs-3 text-primary">
                                <FontAwesomeIcon icon={faCalendar}/>
                            </div>
                            <div className="fw-bold text-uppercase text-black">
                                { format(new Date(selectedDate), 'dd/MM/yyyy') }
                            </div>
                        </div>
                    </div>

                    <div className="bg-secondary p-4 border-1 border-primary flex-fill" style={{borderStyle: 'solid'}}>
                        <div className="d-flex flex-row gap-3 align-items-center">
                            <div className="fs-3 text-primary">
                                <FontAwesomeIcon icon={faClock}/>
                            </div>
                            <div className="fw-bold text-uppercase text-black">
                                { new Date(selectedDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                                - { addMinutes(selectedDate, (slot ? slot : 60)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                            </div>
                        </div>
                    </div>

                </div>
            </>}
            <div style={containerActionsStyles}>
                <hr/>
                <div style={actionsStyles}>
                    <div>
                        <Button className="bg-white" variant="outline-primary" onClick={cancel}>Indietro</Button>
                    </div>
                    <div>
                        <Button variant="primary" disabled={!selectedDate} onClick={() => selectedDate && props.setDateAndTime(selectedDate)}>Prossimo</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
