import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import MapSportello from "../components/MapSportello";
import {BookingBase, Prestazione, Struttura} from "../assets/interface/interface";
import axios from "axios";
import {format} from "date-fns";
import InfoSportelloDetail from "../components/InfoSportelloDetail";
import Loading from "../components/Loading";
import {config} from "../config";
import {ToastContext} from "../services/ToastService";

const BookingDetails = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [strutture, setStrutture] = useState<Struttura[]>([]);
    const [prestazioni, setPrestazioni] = useState<Prestazione[]>([]);
    const [book, setBook] = useState<BookingBase>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {showToast} = useContext(ToastContext)!;

    const getStrutture = async () => {
        try {
            const response = await axios.get<Struttura[]>(
                `${config.apiUrl}/front/get_struttura.php`
            );
            setStrutture(response.data);
        } catch (error) {
            showToast("danger", "Errore", "Errore durante la richiesta delle strutture.");
            console.error('Errore durante la richiesta API:', error);
        }
    };
    const getBookById = async (id: string | undefined) => {
        try {
            const response = await axios.get(`${config.apiUrl}/front/get_event_by_order_id.php`, { params: { order_id: id } });
            if (response.data) {
                setBook(response.data);
            } else {
                navigate("/");
            }
        } catch (error) {
            showToast("danger", "Errore", "Errore durante la richiesta della prenotazione.");
            console.error('Errore durante la richiesta API:', error);
        }
    };

    const getPrestazioni = async () => {
        try {
            const response = await axios.get<Prestazione[]>(
                `${config.apiUrl}/front/get_prestazioni.php`
            );
            setPrestazioni(response.data);
        } catch (error) {
            // Gestisci eventuali errori qui
            showToast("danger", "Errore", "Errore durante la richiesta delle prestazioni.");
            console.error('Errore durante la richiesta API:', error);
        }
    };

    useEffect(() => {
        getBookById(id);
        getStrutture();
        getPrestazioni();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    async function cancelBooking() {
        setIsLoading(true);
        try {
            const response = await axios.get(`${config.apiUrl}/front/reject_prenotazione.php`, {
                params: {
                    order: id,
                    email: book?.email
                }
            });
            setBook(response.data);
            setIsLoading(false);
            scrollToTop();
        } catch (error) {
            setIsLoading(false);
            showToast("danger", "Errore", "Errore durante la richiesta di cancellazione della prenotazione.");
            console.error('Errore durante la richiesta API:', error);
        }

    }

    async function acceptBooking() {
        setIsLoading(true);

        try {
            const response = await axios.get(`${config.apiUrl}/front/confirm_prenotazione.php`, {
                params: {
                    order: id,
                    email: book?.email
                }
            });
            setBook(response.data);
            setIsLoading(false);
            scrollToTop();
        } catch (error) {
            setIsLoading(false);
            showToast("danger", "Errore", "Errore durante la richiesta di conferma della prenotazione.");
            console.error('Errore durante la richiesta API:', error);
        }
    }

    if (book) {
        return (
            <div className="container">

                <div className="d-flex flex-row">
                    { book?.record_attivo === "1" && <div className="fs-3 text-primary" style={{marginTop: "45px", marginRight: "19px"}}>
                        <FontAwesomeIcon icon={faCircleCheck}/>
                    </div>}
                    { (book?.record_attivo === "0") && <div className="fs-3 text-red" style={{marginTop: "45px", marginRight: "19px"}}>
                        <FontAwesomeIcon icon={faCircleXmark}/>
                    </div>}

                    <div className="flex-column">
                        <h1>Prenotazione
                            { book?.record_attivo === "0" && <span> Annullata</span>}
                            { book?.record_attivo === "2" && <span> In attesa di conferma</span>}
                            { book?.record_attivo === "1" && <span> Confermata</span>}

                            : {book && prestazioni && prestazioni.filter(p => p.id === book?.tipo_prenotazione)[0]?.descrizione}</h1>
                        <p className="text-black">

                            { book?.record_attivo === "3" && <span>L'appuntamento è fissato per il </span>}
                            { book?.record_attivo === "2" && <span>La prenotazione per l'appuntamento fissato per il </span>}
                            { (book?.record_attivo === "0" || book?.record_attivo === "1") && <span>L'appuntamento fissato per il </span>}

                            <b>{book?.date_prenotazione && format(new Date(book.date_prenotazione), 'dd/MM/yyyy') } alle {book?.date_prenotazione && new Date(book.date_prenotazione).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</b>
                            { book?.record_attivo === "0" && <span> è stato annullato.</span>}
                            { book?.record_attivo === "1" && <span> è stato confermato.</span>}
                            { book?.record_attivo === "2" && <span> è stata presa in carico dallo sportello, appena verificheremo i tuoi dati, ti manderemo una mail di conferma per l'appuntamento.</span>}



                            {book?.record_attivo === "1" && <> <br/><br/>
                                <u><i>Presentarsi per il ritiro di persona senza animale o con delega del proprietario allegando fotocopia di un documento d’Identità valido.</i></u>
                                <br/><br/></>
                               }

                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-9 mb-5">

                        <div className="p-4" style={{background: "#F5F5F5"}} >
                            <div className="wrapper gap-4">
                                <div>
                                    {strutture && book && <MapSportello struttura={strutture.filter(str => str.id === book.sportello)[0]}></MapSportello>}
                                </div>
                                <div className="bg-secondary p-4 border-1 border-primary" style={{borderStyle: 'solid'}}>
                                    {strutture && book && <InfoSportelloDetail book={book} struttura={strutture.filter(str => str.id === book.sportello)[0]} date={book.date_prenotazione}></InfoSportelloDetail>}
                                </div>

                                {book?.record_attivo === "3" && <>
                                    <div className="bg-red p-4 flex-fill d-flex flex-row align-items-center gap-3 cursor-pointer" onClick={cancelBooking}>
                                        <div className="fs-3 text-primary text-white">
                                            <FontAwesomeIcon icon={faCircleXmark}/>
                                        </div>
                                        <div className="fw-bold text-uppercase text-white">
                                            CANCELLA PRENOTAZIONE
                                        </div>
                                    </div>

                                    <div className="bg-primary p-4 flex-fill d-flex flex-row align-items-center gap-3 cursor-pointer" onClick={acceptBooking}>
                                        <div className="fs-3 text-primary text-white">
                                            <FontAwesomeIcon icon={faCircleCheck}/>
                                        </div>
                                        <div className="fw-bold text-uppercase text-white">
                                            CONFERMA PRENOTAZIONE
                                        </div>
                                    </div>
                                </>}

                            </div>

                        </div>
                    </div>
                    <div className="col-md-3">
                        { (book?.record_attivo === "3" || book?.record_attivo === "1") && <div className="bg-secondary p-4">
                            <div>
                                <div className="d-flex flex-row gap-3 align-items-center">
                                    <div className="fs-3 text-primary">
                                        <FontAwesomeIcon icon={faTriangleExclamation}/>
                                    </div>
                                    <div className="fw-bold text-uppercase text-primary">
                                        ATTENZIONE
                                    </div>
                                </div>
                                <p>

                                </p>
                                {book?.record_attivo === "3" && <p>Se non confermi la seguente prenotazione entro 4 ore dalla richiesta, questa verrà annullata automaticamente.</p>}
                                {book?.record_attivo === "1" && <p>Se necessiti di cancellare la seguente prenotazione devi chiamare lo sportello scelto.</p>}

                            </div>
                        </div>}

                    </div>
                </div>

                { isLoading && <Loading/>}

            </div>


        );
    } else {
        return <Loading />;
    }
};

export default BookingDetails;
