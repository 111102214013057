import React from 'react';
const Loading = () => {
    // Implementa qui la logica per creare una prenotazione
    return (
        <div className="loader-container">
            <div className="spinner"></div>
        </div>
    );
};

export default Loading;
